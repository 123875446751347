import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Carousel,
} from "react-bootstrap";
import viviendasData from "../properties.json"; // Importar el archivo JSON

const Header = () => (
  <header className="bg-dark text-white text-center py-5">
    <h1>Cotización para el Alquiler de su Vivienda</h1>
    <p className="lead">Comparación con propiedades similares en la zona</p>
  </header>
);

const ViviendaCard = ({ title, imgSrc, description, price, onCardClick }) => (
  <Col xs={10} md={6} lg={4} className="mb-4 offset-1 offset-md-0 offset-lg-0">
    <Card onClick={onCardClick} style={{ cursor: "pointer" }}>
      <div className="position-relative">
        <Card.Img variant="top" src={imgSrc} alt={title} />
      </div>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{description}</Card.Text>
        <div className="d-flex justify-content-between align-items-center">
          <small className="text-muted">{price}</small>
        </div>
      </Card.Body>
    </Card>
  </Col>
);

const ViviendasComparativas = ({ onCardClick }) => (
  <Container className="my-5">
    <h2 className="text-center mb-4">Viviendas Comparativas en la Zona</h2>
    <Row>
      {viviendasData.map((vivienda) => (
        <ViviendaCard
          key={vivienda.id}
          title={vivienda.title}
          imgSrc={vivienda.image} // Mostrar la primera imagen en la card
          description={vivienda.description}
          price={vivienda.price}
          onCardClick={() => onCardClick(vivienda.id)}
        />
      ))}
    </Row>
  </Container>
);

const ViviendaModal = ({ selectedVivienda, onClose }) => {
  const vivienda = viviendasData.find(
    (vivienda) => vivienda.id === selectedVivienda
  );

  if (!vivienda) return null;

  return (
    <Modal show={!!selectedVivienda} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{vivienda.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Carousel touch={true}>
          {vivienda.images.map((img, index) => (
            <Carousel.Item key={index}>
              <img
                className="d-block max-w-100"
                style={{
                  maxHeight: "400px",
                  objectFit: "cover",
                  margin: "0 auto",
                }}
                src={img}
                alt={`Slide ${index + 1}`}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const PropuestaAlquiler = () => (
  <section className="bg-light py-5">
    <Container>
      <h2 className="text-center mb-4">
        Propuesta de Alquiler para su Vivienda
      </h2>
      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>Detalles de la Vivienda</Card.Title>
              <Card.Text>
                Su vivienda cuenta con 3 habitaciones amobladas (1 en suite), 3
                baños en total, cocina amoblada y equipada, sala y comedor
                integrado. Lavadero. Parrilla. Termocalefón en toda la casa.
                Garage para 2 autos, portón eléctrico. Aire acondicionado en
                todos los ambientes (5 en total), 4 ventiladores de techo.
              </Card.Text>

              <Card.Title className="mt-4">Propuesta de Alquiler</Card.Title>
              <Card.Text>
                <strong>Precio sugerido:</strong> Gs. 3.500.000 - Gs. 4.200.000
              </Card.Text>

              <div className="text-center mt-4">
                <Button
                  variant="primary"
                  size="lg"
                  href="https://api.whatsapp.com/send/?phone=595992379313&text&type=phone_number"
                  target="_blank"
                >
                  Aceptar Propuesta
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </section>
);

const Footer = () => (
  <footer className="bg-dark text-white text-center py-3">
    <p className="mb-0">&copy; 2024. Todos los derechos reservados.</p>
  </footer>
);

const Home = () => {
  const [selectedVivienda, setSelectedVivienda] = useState(null);

  const handleCardClick = (viviendaId) => {
    setSelectedVivienda(viviendaId);
  };

  const handleCloseModal = () => {
    setSelectedVivienda(null);
  };

  return (
    <div>
      <Header />
      <ViviendasComparativas onCardClick={handleCardClick} />
      <PropuestaAlquiler />
      <Footer />
      <ViviendaModal
        selectedVivienda={selectedVivienda}
        onClose={handleCloseModal}
      />
    </div>
  );
};

export default Home;
